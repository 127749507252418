import {
  IdTokenClaims,
  SigninPopupArgs,
  SigninRedirectArgs,
  SigninSilentArgs,
  SignoutRedirectArgs,
  User,
  UserManager,
  UserManagerSettings
} from 'oidc-client-ts';

export interface AuthClientOptions extends UserManagerSettings {}

// @link https://github.com/auth/auth-spa-js/blob/main/src
export class AuthClient {
  // Private static property to hold the single instance of the property
  private static _userManager: UserManager | null = null;

  private readonly defaultOptions: Partial<AuthClientOptions> = {};

  private readonly client: UserManager;

  private readonly options: AuthClientOptions;

  constructor(options: AuthClientOptions) {
    this.options = {
      ...this.defaultOptions,
      ...options
    };
    this.client = AuthClient.getClientInstance(this.options);
  }

  // Static method to get the singleton property
  static getClientInstance(options: AuthClientOptions): any {
    if (!AuthClient._userManager) {
      // Create the property if it doesn't exist
      AuthClient._userManager = new UserManager(options);
    }
    return AuthClient._userManager;
  }

  async checkSession(options?: SigninSilentArgs) {
    // Check if there is a user logged in
    const user = await this.client.getUser();
    if (user && !user.expired) return;

    // sign in silently
    try {
      await this.client.signinSilent(options);
    } catch (error) {
      console.error('auth:error', error);
    }
  }

  async getUser(): Promise<User | undefined> {
    const user = await this.client.getUser();
    return user ?? undefined;
  }

  async isAuthenticated(): Promise<boolean> {
    const user = await this.getUser();
    return !!user;
  }

  async getIdTokenClaims(): Promise<IdTokenClaims | undefined> {
    const user = await this.client.getUser();
    return user?.profile;
  }

  loginWithRedirect(options?: SigninRedirectArgs): Promise<void> {
    return this.client.signinRedirect(options);
  }

  loginWithPopup(options?: SigninPopupArgs): Promise<User> {
    return this.client.signinPopup(options);
  }

  logout(options?: SignoutRedirectArgs): Promise<void> {
    return this.client.signoutRedirect(options);
  }

  async getToken(): Promise<string | undefined> {
    const user = await this.client.getUser();
    return user?.access_token;
  }

  async getTokenSilently(options?: SigninSilentArgs): Promise<string | undefined> {
    const user = await this.client.signinSilent(options);
    return user?.access_token;
  }

  async getTokenWithPopup(options?: SigninPopupArgs): Promise<string | undefined> {
    const user = await this.client.signinPopup(options);
    return user?.access_token;
  }

  handleCallback(url?: string | undefined): Promise<void | User> {
    return this.client.signinCallback(url);
  }
}

import { onActivated, onDeactivated, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useFinancialStore } from '@/stores';
import { DateTimeSchema, MessageSchema, NumberSchema } from '@/locales';
import { EventConstants } from '@repo/core/constants';
import { useDxEventBus, useDxToast } from '@repo/vue/utils';

export const useDxClientProcess = () => {
  // locale
  const { t } = useI18n<[MessageSchema, DateTimeSchema, NumberSchema]>();

  // utils
  const dxToast = useDxToast();
  const dxEventBus = useDxEventBus();

  // stores
  const financialStore = useFinancialStore();

  // data
  const isTabActive = ref<boolean>(false);
  const isTabSaving = ref<boolean>(false);

  // hooks
  onMounted(() => {
    isTabActive.value = true;
  });
  onUnmounted(() => {
    isTabActive.value = false;
  });
  onActivated(() => {
    isTabActive.value = true;
  });
  onDeactivated(() => {
    isTabActive.value = false;
  });

  // methods
  const addPrior = async () => {
    try {
      const response = await financialStore.addPriorFinancialYear();
      if (response.length > 0) {
        financialStore.setSelectedPeriod(response[0]);

        dxEventBus.emit(EventConstants.financialYearsChanged);
        dxEventBus.emit(EventConstants.selectedYearChanged, response[0]);
      } else {
        console.log('Error loading Prior', response);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) dxToast.error(error?.response?.data?.message ?? t('message.error'));
    }
  };

  return {
    isTabActive,
    isTabSaving,
    addPrior
  };
};

import * as _ from 'lodash-es';
import moment from 'moment';
import { RibbonItem } from '@repo/core/models';
import { ResourceConfig } from '@repo/core/resources';
import { useClientStore, useDirectImportStore, useFinancialStore, usePracticeStore, useRibbonStore } from '@/stores';

export const useDxRibbonItem = () => {
  // stores
  const practiceStore = usePracticeStore();
  const clientStore = useClientStore();
  const financialStore = useFinancialStore();
  const ribbonStore = useRibbonStore();
  const directImportStore = useDirectImportStore();

  const loadRibbonItems = async (excludeFinYears: boolean): Promise<RibbonItem[]> => {
    try {
      const getFunctions: Promise<any>[] = [
        ribbonStore.fetchRibbonItems(),
        directImportStore.fetchIntegrations(),
        directImportStore.fetchClientConnection()
      ];
      if (!excludeFinYears) getFunctions.push(financialStore.fetchFinancialYears());

      const result = await Promise.all(getFunctions);
      let ribbonItems = result[0] || [];
      _.forEach(ribbonItems, (item) => {
        item.iconType = 'fa';
      });
      const finYears = result[3] || [];
      const practice = practiceStore.currentPractice;
      const client = clientStore.currentClient;
      if (practice?.id && !practice?.cyncBeta) {
        ribbonItems = _.filter(ribbonItems, (x) => {
          return !(
            _.toUpper(x.id) == '60194653-4A0F-4DA7-B74A-63F2144C4B06' ||
            _.toUpper(x.id) == '853479FC-BA56-4AC0-A4BD-B7F908BED8F9' ||
            _.toUpper(x.id) == '21648A37-ADB4-44AD-A96F-92D87B3DA8A1' ||
            _.toUpper(x.id) == '40F5D14B-237A-4319-B195-400DDEDB196A'
          );
        });
      }
      if (client?.id && client.framework) {
        ribbonItems = _.filter(ribbonItems, (x_1) => {
          return _.toUpper(x_1.id) !== 'A76D357F-4BC1-4D79-857F-56C8F571C7AE';
        });
      }
      if (!ResourceConfig.enableCaseware)
        ribbonItems = _.filter(ribbonItems, (x_2) => {
          return _.toUpper(x_2.id) !== '9C8EA79C-4200-4B09-AE43-DCE19FC45A74';
        });
      if (!excludeFinYears && finYears.length > 0) {
        let index = finYears.length;
        _.forEach(
          _.filter(_.orderBy(finYears, ['end'], ['desc']), (i) => {
            return i.showOnRibbon || i.current;
          }),
          (i_1) => {
            ribbonItems.push({
              id: '82d2d6d0-b1b5-4134-8377-92a5d8809ba5',
              icon: 'fa fa-calendar',
              name: i_1.id,
              title: moment(i_1.end).format('YYYY'),
              items: [],
              parentId: null,
              priority: index,
              iconType: 'fa'
            });
            index--;
          }
        );
      }
      const directImportsResult = result[1] || [];
      if (directImportsResult.length > 0) {
        let index_1 = directImportsResult.length;
        _.forEach(directImportsResult, (i_2) => {
          if (
            i_2 &&
            i_2.supportedCountries &&
            i_2.supportedCountries.length > 0 &&
            (i_2.supportedCountries[0] == '*' || _.includes(i_2.supportedCountries, client.country?.code))
          ) {
            ribbonItems.push({
              id: i_2.id,
              icon: window.atob(i_2.icon),
              name: 'directImport',
              title: i_2.name,
              items: [],
              parentId: null,
              priority: index_1,
              iconType: 'svg',
              data: i_2
            });
            index_1--;
          }
        });
      } else {
        ribbonItems.push({
          id: '1e2f654a-7079-4624-96c4-96c64519f9b8',
          icon: 'fa fa-exclamation-triangle',
          name: 'directImport',
          title: '',
          items: [],
          parentId: null,
          priority: 1,
          iconType: 'fa'
        });
      }
      return ribbonItems;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return { loadRibbonItems };
};

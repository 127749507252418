export const EventConstants = {
  save: 'save',
  reload: 'reload',
  addTab: 'addTab',
  tabOff: 'tabOff',
  removeTab: 'removeTab',
  closeTab: 'closeTab',
  closeTabs: 'closeTabs',
  closeClient: 'closeClient',
  confirmTabClose: 'confirmTabClose',
  notifications: 'Notifications',
  reloadClientMenu: 'reloadClientMenu',
  reloadPractices: 'reloadPractices',
  reloadNotifications: 'reloadNotifications',
  accountLinkSelected: 'accountLinkSelected',
  accountLinkComplete: 'AccountLinkComplete',
  notificationDismissed: 'NotificationDismissed',
  practiceChanged: 'PracticeChanged',
  currentClientUpdate: 'currentClientUpdate',
  resetClientClaims: 'resetClientClaims',
  refreshCurrentPractice: 'refreshCurrentPractice',
  refreshGridFilters: 'refreshGridFilters',
  clientUpdated: 'ClientUpdated',
  smartDocUpdate: 'SmartDocUpdate',
  clientMenuUpdated: 'clientMenuUpdated',
  selectedYearChanged: 'selectedYearChanged',
  tbRowDoubleClick: 'tbRowDoubleClick',
  financialYearsChanged: 'financialYearsChanged',
  financialYearChangedDone: 'financialYearChangedDone',
  xbrlStepUpdate: 'xbrlStepUpdate',
  xbrlNextStep: 'xbrlNextStep',
  refreshXbrlContext: 'refreshXbrlContext',
  canvasCreated: 'canvas:created',
  canvasClosed: 'canvas:closed',
  canvasElementsSelected: 'canvas:element:selected'
};

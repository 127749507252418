<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue';

// events
const onPreloadError = (event: Event) => {
  event.preventDefault();
  console.error('onPreloadError', event);
  window.location.reload();
};

// hooks
onMounted(() => {
  window.addEventListener('vite:preloadError', onPreloadError);
});

onBeforeUnmount(() => {
  window.removeEventListener('vite:preloadError', onPreloadError);
});
</script>

<template>
  <RouterView />
</template>

<style scoped lang="scss"></style>

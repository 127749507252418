import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import { createAuth, createLoader, createDates } from '@repo/vue/plugins';
import { createSetup } from '@/plugins/app';
import { createTheme } from '@/plugins/theme';
import { createLogger } from 'vue-logger-plugin';
import { DataTableDirective } from '@/shared/directives';
import { AuthConfig } from '@repo/core/security';
import { Icon } from '@iconify/vue';

// @ag-grid-community/core will always be implicitly available
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridVue } from '@ag-grid-community/vue3';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

import App from '@/App.vue';
import router from '@/router';

import '@/assets/styles/main.scss';

import PiniaLogger from 'pinia-logger';
/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages';

// ag grid setup
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  RichSelectModule,
  RowGroupingModule
]);

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-056045}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Data_Prime}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_May_2025}____[v3]_[01]_MTc0NjM5OTYwMDAwMA==337f9df46bb92e45b5cc6a059680c2bb'
);

const i18n = createI18n({
  locale: 'en-ZA',
  messages
});

const logger = createLogger({
  enabled: true,
  consoleEnabled: true,
  callerInfo: true,
  level: 'warn'
});

const pinia = createPinia();
pinia.use(
  PiniaLogger({
    expanded: false,
    showDuration: true,
    showTime: true,
    logErrors: true,
    showPineapple: true,
    showStoreName: true,
    disabled: import.meta.env.MODE === 'production' || import.meta.env.MODE === 'ci'
    // use a filter to only log certain actions
    // filter: ({ name }) => !_.includes(['startLoader', 'stopLoader', 'clearLoader'], name)
    // alternatively, you can specify the actions you want to log
    // if undefined, all actions will be logged
    // actions: ['decrementCounter']
  })
);
// pinia.use(PiniaPlugin);

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(logger);
app.use(pinia);

app.use(createDates());
app.use(createTheme());
app.use(createLoader());
app.use(createAuth(AuthConfig));

app.directive('data-table', DataTableDirective);
app.component('Icon', Icon);
app.component('AgGridVue', AgGridVue);

app.use(createSetup());

import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { closeModal, Modal, openModal, popModal, promptModal, pushModal } from 'jenesius-vue-modal';
import { ModalOptions } from 'jenesius-vue-modal/dist/types/utils/Modal';
import { useAppStore } from '@repo/vue/stores';
import { ResourceConfig } from '@repo/core/resources';
import { MessageConstants } from '@repo/core/constants';

export const useDxModal = () => {
  // computed
  const target = computed(() => window.parent);

  // stores
  const appStore = useAppStore();

  // state
  const { isIFrame } = storeToRefs(appStore);

  // computed
  const hasOpenModal = computed(() => !!document.querySelector('.p-dialog-mask[style*="z-index"], .p-sidebar-active'));

  // methods
  const open = async (component: any, props?: any): Promise<Modal> => {
    const result = await openModal(component, props);
    if (isIFrame.value) {
      const message: any = {
        event: MessageConstants.openModal
      };
      sendMessage(message);
    }
    return result;
  };

  const prompt = async (component: any, props?: any, options?: Partial<ModalOptions>): Promise<unknown> => {
    const result = (await promptModal(component, props, options)) as Modal;
    if (isIFrame.value) {
      const message: any = {
        event: MessageConstants.openModal
      };
      sendMessage(message);
    }
    return result;
  };

  const push = async (component: any, props?: any): Promise<Modal> => {
    const result = await pushModal(component, props);
    if (isIFrame.value) {
      const message: any = {
        event: MessageConstants.openModal
      };
      sendMessage(message);
    }
    return result;
  };

  const pop = async (): Promise<void> => {
    const result = await popModal();
    if (!hasOpenModal.value && isIFrame.value) {
      const message: any = {
        event: MessageConstants.closeModal
      };
      sendMessage(message);
    }
    return result;
  };

  const close = (): Promise<void> => {
    const result = closeModal();

    if (!hasOpenModal.value && isIFrame.value) {
      const message: any = {
        event: MessageConstants.closeModal
      };
      sendMessage(message);
    }
    return result;
  };

  const closeById = async (id: number): Promise<void> => {
    const result = await closeById(id);
    if (!hasOpenModal.value && isIFrame.value) {
      const message: any = {
        event: MessageConstants.closeModal
      };
      sendMessage(message);
    }
    return result;
  };

  const sendMessage = (data: any) => {
    if (target.value && ResourceConfig.webAngularUrl) {
      const message = JSON.parse(JSON.stringify(data));
      console.log('vue:sendMessage', message);
      target.value.postMessage(message, ResourceConfig.webAngularUrl);
    }
  };

  return {
    open,
    prompt,
    push,
    pop,
    close,
    closeById
  };
};

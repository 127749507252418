export const MessageConstants = {
  init: 'init',
  login: 'login',
  logout: 'logout',
  loadPage: 'loadPage',
  loadTab: 'loadTab',
  removeTab: 'removeTab',
  tabOff: 'tabOff',
  openModal: 'openModal',
  closeModal: 'closeModal',
  reloadClientMenu: 'reloadClientMenu',
  financialYearsChanged: 'financialYearsChanged',
  selectedYearChanged: 'selectedYearChanged',
  accountLinkComplete: 'accountLinkComplete',
  closeClient: 'closeClient',
  confirmTabClose: 'confirmTabClose',
  save: 'save'
};

import { DatesPlugin } from './plugin';

/**
 * Creates the Dates plugin.
 *
 * @returns An instance of DatesPlugin
 */
export const createDates = () => {
  return new DatesPlugin();
};
